import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import ContentComponent from "../../Content/Content";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Select from "../../ReactHookForm/Select";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import ContactContainer from "../../Contact/ContactContainer";
import PhoneNumber from "../../Contact/PhoneNumber";
import { basysEndpoints, sassEndpoints } from "../../../constants/endpoints";
import { useAxios } from "../../../hooks";
import { timezones } from "../../../constants/general";
import { validationRules } from "../../../constants/validationRules";
import { partnerRoutes } from "../../../constants/routes";
import { stringFormat } from "../../../utils/stringHelpers";
import FormControl from "@mui/material/FormControl";
import { GATEWAY_PROVIDER_IDS } from "../../../constants/global";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import GatewayLink from "./GatewayLink";
import { CountriesContext } from "../../../contexts/CountriesContext";
import "./gateway.scss";

const validationAlertMessage = [
  "Please correct validation errors highlighted in red.",
];

const contactRequireds = [
  "firstName",
  "lastName",
  "company",
  "address1",
  "country",
  "state",
  "city",
  "postalCode",
  "email",
  "phone",
];

const steps = ["Gateway Selection", "Gateway Info", "Primary Contact"];

const GatewayCreate = ({ history }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertDialogProps, setAlertDialogProps] = useState({
    alertTitle: "Error",
    alertLevel: "error",
    alertMessages: validationAlertMessage,
    closeButtonText: "Ok",
    onCloseButtonClick: () => setIsAlertOpen(false),
  });
  const methods = useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const isStepOptional = (step) => {
    return step === -1;
  };

  const [gatewaySelectDefault] = useState(GATEWAY_PROVIDER_IDS.IQPRO);
  const [isExisting, setExisting] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    if (activeStep === 1) {
      methods.setFocus("name");
    }
    if (activeStep === 2) {
      methods.setFocus("primary_contact.first_name");
    }
  }, [activeStep]);

  const linkGateway = useRef();
  const countries = useContext(CountriesContext);

  const { data: gatewayProviders, loading: isGatewayProvidersLoading } =
    useAxios(
      {
        method: "GET",
        url: basysEndpoints.gateway_prodivders,
      },
      { initialData: [], dataTransform: (data) => data.data },
    );

  const displayMessages = (alertMessage) => {
    setAlertDialogProps({
      alertTitle: "Error!",
      alertLevel: "error",
      alertMessages: [alertMessage],
      closeButtonText: "Ok",
      onCloseButtonClick: () => setIsAlertOpen(false),
    });
    setIsAlertOpen(true);
  };

  useEffect(() => {
    if (!isGatewayProvidersLoading) {
      setShowBackdrop(false);
      setSelectedProvider(
        gatewayProviders.find(
          (p) => p.gateway_provider_id === GATEWAY_PROVIDER_IDS.IQPRO,
        ),
      );
    }
  }, [isGatewayProvidersLoading]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    const step0triggers = ["gateway_provider_id"];
    const step1triggers = [
      "name",
      "description",
      "receiptEmail",
      "contact_phone",
      "phone",
    ];
    const triggers = [step0triggers, step1triggers];
    const result = await methods.trigger(triggers[activeStep]);

    if (!result) {
      displayMessages(validationAlertMessage);
      return false;
    }

    //Pre-populate Primary Contact Company from Gateway Name if Company is empty
    if (activeStep === 1) {
      let gatewayName = methods.getValues("name");
      let company = methods.getValues("primary_contact.company");

      if (!company) {
        methods.setValue("primary_contact.company", gatewayName);
        methods.clearErrors("primary_contact.company");
      }
    }

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      //Guards against skipping a step...
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleOnSubmit = (data) => {
    createGateway(data);
  };

  const handleOnError = () => displayMessages(validationAlertMessage);

  const handleReset = () => {
    setActiveStep(0);
    throw new Error("You can't skip a step that isn't optional.");
  };

  const createGateway = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      merchantId: data.description,
      website: data.website,
      phone: data.phone,
      phoneExternal: data.phone_ext,
      receiptEmail: data.receiptEmail,
      timezone: data.timezone,
      categoryCode: data.categoryCode,
      addresses: [
        {
          isPrimary: true,
          address: {
            addressLine1: data.primary_contact.address_line_1,
            addressLine2: data.primary_contact.address_line_2,
            city: data.primary_contact.city,
            state: data.primary_contact.state,
            postalCode: data.primary_contact.postal_code,
            country: data.primary_contact.country,
            firstName: data.primary_contact.first_name,
            lastName: data.primary_contact.last_name,
            email: data.primary_contact.email,
            phone: data.primary_contact.phone,
            fax: data.primary_contact.fax,
            company: data.primary_contact.company,
          },
        },
      ],
      gatewayMetaData: [],
    };
    setShowBackdrop(true);
    const source =
      selectedProvider?.code === "IQPRO" ? "FP" : selectedProvider?.code;
    const url = stringFormat(sassEndpoints.gateways.create, [source]);
    axios
      .post(url, payload)
      .then(function (response) {
        showSnackBar("Gateway has been created!");
        history.push(
          `${partnerRoutes.gateway_view.replace(
            ":id",
            response.data.data.gatewayId,
          )}`,
        );
      })
      .catch(function (error) {
        displayMessages(error?.response?.data.statusDetails);
      })
      .finally(function () {
        setShowBackdrop(false);
      });
  };

  const showSnackBar = (message) => {
    setSnackbarProps({
      message: message,
      severity: "success",
      onClose: () => setSnackbarOpen(false),
    });
    setSnackbarOpen(true);
  };

  return (
    <FormProvider {...methods}>
      <ContentComponent
        spinnerOpen={showBackdrop}
        useFixedWidth={false}
        title="Create Gateway"
        headerContent={
          <>
            <Button
              onClick={() => history.push(partnerRoutes.gateway)}
              color="primary"
              sx={{ fontSize: "1rem" }}
              className="backbutton-color"
            >
              <ChevronLeft sx={{ fontSize: "1.5rem", marginLeft: "-10px" }} />
              Back to List
            </Button>
            <div className="line-progress-container">
              {" "}
              {activeStep === 1 && isExisting ? (
                <LinearProgress
                  sx={{ height: 15 }}
                  variant="determinate"
                  value={100}
                />
              ) : (
                <LinearProgress
                  sx={{ height: 15 }}
                  variant="determinate"
                  value={
                    activeStep === steps.length
                      ? 100
                      : (100 / steps.length) * (activeStep + 1)
                  }
                />
              )}
            </div>

            {activeStep === 1 && isExisting ? (
              <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
                {selectedProvider?.code === "BASYSIQ"
                  ? `${selectedProvider.description} Account (Gateway) List`
                  : `${selectedProvider.description} Gateway List`}
              </Typography>
            ) : (
              <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
                {steps[activeStep]}
              </Typography>
            )}
          </>
        }
        bodyContent={
          <Box sx={{ width: "100%", pt: 3 }}>
            <Stepper activeStep={activeStep} sx={{ display: "none" }}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                {/* Gateway Selection Step */}
                <Grid
                  container
                  spacing={2}
                  sx={{ pr: 1, display: activeStep === 0 ? "" : "none" }}
                >
                  <Grid item xs={12}>
                    <Select
                      name={`gateway_provider_id`}
                      control={methods.control}
                      label="Select a gateway provider"
                      defaultValue={gatewaySelectDefault}
                      sx={{ width: "30%" }}
                      rules={{ required: true }}
                      errors={methods.formState.errors}
                      data-cy="gateway-link-select"
                      onChange={(value) =>
                        setSelectedProvider(
                          gatewayProviders.find(
                            (p) => p.gateway_provider_id === value,
                          ),
                        )
                      }
                    >
                      <MenuItem key="payment-gateway-default" value="">
                        Select a Payment Gateway
                      </MenuItem>
                      {gatewayProviders.map((gatewayProvider) => {
                        return (
                          <MenuItem
                            key={`${gatewayProvider.gateway_provider_id}`}
                            value={gatewayProvider.gateway_provider_id}
                          >
                            {gatewayProvider.description}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    <FormControl>
                      <FormLabel
                        id="gateway-radio-label"
                        sx={{ fontSize: "14px", marginTop: "20px" }}
                      >
                        Tell us if this Gateway is:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="gateway-radio-label"
                        defaultValue={"new"}
                        name="radio-buttons-group-gateway"
                        onChange={(e) => {
                          setExisting(e.target.value === "existing");
                        }}
                      >
                        <FormControlLabel
                          labelPlacement="end"
                          value={"new"}
                          control={<Radio />}
                          label="New"
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value={"existing"}
                          control={<Radio />}
                          label="Existing"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Gateway Info Step */}
                {isExisting ? (
                  <>
                    {" "}
                    {activeStep === 1 && (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          pr: 1,
                          display: activeStep === 1 ? "" : "none",
                        }}
                      >
                        <GatewayLink
                          gatewayProviderId={
                            selectedProvider?.gateway_provider_id
                          }
                          gatewayType={selectedProvider?.code}
                          showSnackbar={showSnackBar}
                          setShowBackdrop={setShowBackdrop}
                          displayMessages={displayMessages}
                          linkGateway={linkGateway}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      pr: 1,
                      display: activeStep === 1 ? "" : "none",
                    }}
                  >
                    <Grid item xs={12}>
                      <RegisteredTextField
                        fullWidth
                        label="Gateway Name"
                        rules={{
                          required: true,
                          maxLength: validationRules.maxLength50,
                        }}
                        name={`name`}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RegisteredTextField
                        fullWidth
                        label="Basys MID"
                        name={`description`}
                        rules={{
                          pattern: validationRules.alphanumericSpace,
                          maxLength: validationRules.maxLength25,
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RegisteredTextField
                        fullWidth
                        label="MCC"
                        name={`categoryCode`}
                        rules={{
                          pattern: validationRules.alphanumericSpace,
                          maxLength: validationRules.maxLength5,
                          required: false,
                        }}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RegisteredTextField
                        fullWidth
                        label="Website"
                        name={`website`}
                        rules={{
                          maxLength: validationRules.maxLength255,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RegisteredTextField
                        fullWidth
                        label="Receipt Email"
                        name={`receiptEmail`}
                        rules={{
                          maxLength: validationRules.maxLength320,
                          pattern: validationRules.email,
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <PhoneNumber
                        name={`phone`}
                        label="Phone"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RegisteredTextField
                        fullWidth
                        label="Phone Ext"
                        name={`phone_ext`}
                        rules={{
                          maxLength: validationRules.maxLength16,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="time-fee-container">
                      {" "}
                      {selectedProvider?.code === "IQPRO" && (
                        <Grid item sx={{ width: "49%" }}>
                          <Select
                            control={methods.control}
                            name={`timezone`}
                            label="Time Zone"
                            defaultValue={"US/Central"}
                          >
                            <MenuItem key="timezone-blank" value=""></MenuItem>
                            {timezones.map((timezone) => (
                              <MenuItem
                                key={`${timezone.value}`}
                                value={timezone.value}
                              >
                                {timezone.display}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {/* Primary Contact Step */}
                <Grid
                  container
                  spacing={2}
                  sx={{ pr: 1, display: activeStep === 2 ? "" : "none" }}
                >
                  <Grid item>
                    <ContactContainer
                      type="primary_contact"
                      required={contactRequireds}
                      countries={countries}
                      country="US"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        }
        footerContent={
          <>
            <Box>
              <Button
                size="small"
                variant="contained"
                color="neutrals"
                onClick={handleBack}
                sx={{ mr: 1, display: activeStep === 0 && "none" }}
              >
                Cancel
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  sx={{ mr: 1 }}
                >
                  Skip
                </Button>
              )}

              {activeStep === 1 && isExisting ? (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    linkGateway.current.onSubmit();
                  }}
                  sx={{ mr: 1, display: activeStep === steps.length && "none" }}
                >
                  Link Gateway
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1
                      ? methods.handleSubmit(handleOnSubmit, handleOnError)
                      : handleNext
                  }
                  sx={{ mr: 1, display: activeStep === steps.length && "none" }}
                >
                  {activeStep === steps.length - 1
                    ? "Create Gateway"
                    : "Continue"}
                </Button>
              )}
            </Box>
          </>
        }
        snackbarOpen={snackbarOpen}
        snackbarProps={snackbarProps}
        alertDialogOpen={isAlertOpen}
        alertDialogProps={alertDialogProps}
      />
    </FormProvider>
  );
};

export default GatewayCreate;

GatewayCreate.propTypes = {
  history: PropTypes.object.isRequired,
};
