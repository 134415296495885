import Dinero from "dinero.js";
import { convertToInt } from "../../../utils/dineroFormatters";

export const ADJUSTMENT_TYPES = {
  FLAT: "flat",
  PERCENTAGE: "percentage",
  NONE: "noadj",
};

export const calculateTotal = (
  baseAmount,
  taxRate,
  adjType = ADJUSTMENT_TYPES.NONE,
  adjValue = 0,
  lineItem = false,
) => {
  let dineroAmount = Dinero({ amount: baseAmount ?? 0 });
  const taxDinero = dineroAmount.multiply(
    Dinero({
      amount: convertToInt(taxRate),
      precision: 5,
    }).toUnit(),
  );
  dineroAmount = dineroAmount.add(taxDinero);
  if (adjType !== ADJUSTMENT_TYPES.NONE) {
    let adjustmentDinero =
      adjType === ADJUSTMENT_TYPES.FLAT
        ? Dinero({ amount: adjValue })
        : dineroAmount.multiply(
            Dinero({
              amount: convertToInt(adjValue),
              precision: 5,
            }).toUnit(),
          );
    if (lineItem) {
      return adjustmentDinero.getAmount();
    }
    dineroAmount = dineroAmount.add(adjustmentDinero);
  }
  return dineroAmount.getAmount();
};

export const defaultHiddenFields = {
  firstName: false,
  lastName: false,
  company: true,
  address1: false,
  address2: false,
  country: false,
  state: false,
  city: false,
  postalCode: false,
  email: true,
  phone: true,
  fax: true,
};

export const parseToArray = (requiredObject) => {
  let arr = [];
  Object.entries(requiredObject).forEach((key) => {
    if (key[1]) {
      arr.push(key[0]);
    }
  });
  return arr;
};

export const formatAddresses = (data) => {
  let addresses = [];
  let types = ["billing", "shipping"];
  types.forEach((type) => {
    let filledAddress = false;
    if (data[`${type}`]) {
      Object.entries(data[`${type}`]).forEach((val) => {
        if (val[0] !== "country" && val[1]) {
          filledAddress = true;
        }
      });
      if (filledAddress) {
        const details = data[`${type}`];
        const address = {
          isPhysical: false,
          isShipping: type === "shipping",
          isBilling: type === "billing",
          firstName: details?.first_name || "",
          lastName: details?.last_name || "",
          company: details?.company || "",
          email: details?.email || "",
          phone: details?.phone || "",
          fax: details?.fax || "",
          addressLine1: details?.address_line_1 || "",
          addressLine2: details?.address_line_2 || "",
          city: details?.city || "",
          state: details?.state || "",
          postalCode: details?.postal_code || "",
          country: details?.country || "",
        };
        addresses.push(address);
      }
    }
  });
  return addresses;
};

export const handleCustomField = (
  customFields,
  customFieldsGroup,
  customFieldsData,
) => {
  let customFieldArray = [];
  customFields[customFieldsGroup].forEach((fieldValue, index) => {
    if (fieldValue !== null && fieldValue?.length > 0) {
      customFieldArray.push({
        customFieldId: index,
        customFieldValue:
          //field value here is required to be type [string]
          typeof fieldValue === "object" ? fieldValue : [fieldValue],
      });
    }
  });

  //Send a null value for custom fields to bypass validation when they are empty and not required
  if (customFieldArray.length === 0 && customFieldsData) {
    let group = customFieldsData?.find(
      (group) => group.name === customFieldsGroup,
    );
    let hasRequiredFields = group?.customFields?.some(
      (field) => field.isRequired,
    );
    customFieldArray = hasRequiredFields ? customFieldArray : null;
  }

  return customFieldArray;
};

export const readPaymentAdjustment = (paymentAdjustments) => {
  return paymentAdjustments.length > 0
    ? paymentAdjustments[0].percentage
      ? ADJUSTMENT_TYPES.PERCENTAGE
      : ADJUSTMENT_TYPES.FLAT
    : ADJUSTMENT_TYPES.NONE;
};

export const clearedContactAddress = {
  first_name: "",
  last_name: "",
  company: "",
  address_line_1: "",
  address_line_2: "",
  country: "US",
  state: "",
  city: "",
  postal_code: "",
  email: "",
  phone: "",
  fax: "",
};

export const setAddress = (id, customers) => {
  const address = customers?.addresses?.find(
    (address) => address.customerAddressId === id,
  );
  return formatAsContactAddress(address);
};

export const formatAsContactAddress = (address) => {
  return {
    first_name: address?.firstName || "",
    last_name: address?.lastName || "",
    company: address?.company || "",
    address_line_1: address?.addressLine1 || "",
    address_line_2: address?.addressLine2 || "",
    country: address?.country || "",
    state: address?.state || "",
    city: address?.city || "",
    postal_code: address?.postalCode || "",
    email: address?.email || "",
    phone: address?.phone || "",
    fax: address?.fax || "",
  };
};

export const checkRequired = (errors) => {
  for (const [k, v] of Object.entries(errors)) {
    if (errors[k] === "required") {
      return true;
    }
    if (v && typeof v === "object") {
      if (checkRequired(v)) {
        return true;
      }
    }
  }
  return false;
};
