import React, { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import FormattedTextField from "../../ReactHookForm/FormattedTextField";
import Select from "../../ReactHookForm/Select";
import { Checkbox, FormControlLabel, TextareaAutosize } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { GatewayContext } from "../../../contexts/GatewayContext";
import { TerminalContext } from "./TerminalContext";
import { GatewayConfigContext } from "../../../contexts/GatewayConfigContext";
import { getGatewayConfigFieldVal } from "../../../utils/objectHelpers";
import { formatCurrency } from "../../../utils/stringHelpers";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { calculateTotal } from "./terminalHelpers";
import { CustomFields } from "../CustomFields/GlobalCustomFields";
import { sassEndpoints } from "../../../constants/endpoints";
import axios from "axios";
import { stringFormat } from "../../../utils/stringHelpers";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import RegisteredTextField from "../../ReactHookForm/RegisteredTextField";
import CustomerSelect from "./CustomerSelect";
import { PAYMENT_TYPES } from "./terminalConstants";
import { validationRules } from "../../../constants/validationRules";
const PaymentDetails = () => {
  // Contexts
  const form = useFormContext();
  const gatewayInfo = useContext(GatewayContext);
  const gatewayConfig = useContext(GatewayConfigContext);
  const [customFieldGroups, setCustomFieldGroups] = useState(null);
  const [chosenCustomFields, setChosenCustomFields] = useState(null);
  const [showAdditionalFields, setAdditionalFields] = useState(false);
  const {
    paymentType,
    setPaymentType,
    taxRules,
    setTaxRules,
    customer,
    resetCustomFields,
    setCustomFieldsData,
  } = useContext(TerminalContext);
  const { userSettings } = useContext(UserSettingsContext);
  const taxRate =
    getGatewayConfigFieldVal(
      gatewayConfig?.gatewayConfigSettings,
      "default_values",
      "Merchant",
      "tax_rate",
    ) || null;
  // States
  const amount = useWatch({ name: "amount", defaultValue: 0 });

  const showVerification = () => {
    return gatewayInfo.processors.some((p) => {
      return p.processorType.type === "CreditCard" && !p.isDeleted;
    });
  };

  useEffect(() => {
    let url = stringFormat(sassEndpoints.customFields.category, [
      userSettings.gatewayId,
    ]);
    axios.get(url).then(function (res) {
      if (res.status !== 204) {
        let groupNames = res.data?.data
          ?.filter((x) => x.transaction)
          ?.map((group) => group.name);
        let customFields = res.data?.data
          ?.filter((x) => x.transaction)
          ?.map((fields) => fields.customFields);
        setCustomFieldGroups(groupNames);
        setChosenCustomFields(customFields);
        setCustomFieldsData(res.data?.data);
        if (
          customFields.some((f) => {
            return f.some((c) => {
              return c.isRequired;
            });
          })
        ) {
          setAdditionalFields(true);
        }
      }
    });
  }, []);

  return (
    <div>
      <div>
        <div className="button-toggle">
          <ToggleButtonGroup
            value={paymentType}
            exclusive
            onChange={(e) => {
              if (e?.target?.value === PAYMENT_TYPES.VERIFICATION) {
                form.setValue("amount", 100);
              }
              if (
                paymentType === PAYMENT_TYPES.VERIFICATION &&
                e?.target?.value !== PAYMENT_TYPES.VERIFICATION
              ) {
                form.setValue("amount", 0);
              }
              setPaymentType(e?.target?.value);
            }}
          >
            <ToggleButton value={PAYMENT_TYPES.SALE}>Sale</ToggleButton>
            <ToggleButton value={PAYMENT_TYPES.AUTHORIZATION}>
              Auth
            </ToggleButton>
            {showVerification() && (
              <ToggleButton
                disabled={customer ? true : false}
                value={PAYMENT_TYPES.VERIFICATION}
              >
                Verification
              </ToggleButton>
            )}
            {gatewayInfo.gatewaySettings
              .find((s) => s.code === "SUPPORT_BLIND_CREDITS")
              ?.value.toLowerCase() === "true" && (
              <ToggleButton value={PAYMENT_TYPES.CREDIT}>Credit</ToggleButton>
            )}
          </ToggleButtonGroup>
        </div>
        <div className="pricing-section">
          <div className="input-wrapper">
            <div>
              <div className="combination-inputs">
                <Select
                  name={`currency`}
                  control={form.control}
                  label="Amount"
                  defaultValue={"USD"}
                  disabled={true}
                  errors={form.formState.errors}
                  v2={true}
                >
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                <FormattedTextField
                  type="currency"
                  name="amount"
                  defaultValue={"0.00"}
                  disabled={paymentType === PAYMENT_TYPES.VERIFICATION}
                  rules={{
                    min: {
                      value: 1,
                    },
                    max: {},
                    required: true,
                  }}
                />
              </div>
              {amount <= 0 && form.getFieldState("amount").error && (
                <p style={{ paddingTop: "4px" }} className="error--text">
                  Amount must be great than $0.00
                </p>
              )}
            </div>
            <div className="tax-section">
              {taxRate && !taxRules.taxToTotal && taxRate > 0 && (
                <FormControlLabel
                  name={"taxExempt"}
                  control={
                    <Checkbox
                      color="primary"
                      value={taxRules.taxExempt}
                      onClick={(e) => {
                        setTaxRules({
                          taxExempt: e?.target?.checked,
                          taxToTotal: e?.target?.checked
                            ? false
                            : taxRules.taxToTotal,
                        });
                      }}
                    />
                  }
                  label={"Tax Exempt"}
                />
              )}
              {!taxRules.taxExempt && (
                <FormControlLabel
                  name={"addTax"}
                  control={
                    <Checkbox
                      color="primary"
                      value={taxRules.taxToTotal}
                      checked={taxRules.taxToTotal}
                      onClick={(e) => {
                        setTaxRules({
                          taxExempt: e?.target?.checked
                            ? false
                            : taxRules.taxExempt,
                          taxToTotal: e?.target?.checked,
                        });
                      }}
                    />
                  }
                  label={"Add Tax to Total"}
                />
              )}
            </div>
          </div>

          <div className="input-wrapper">
            <div className="notes-section">
              <InputLabel>Description</InputLabel>
              <TextareaAutosize
                label={"Description"}
                aria-label="Description"
                {...form.register("description")}
                style={{
                  height:
                    !taxRules.taxExempt && taxRate && amount > 0
                      ? "90%"
                      : "85%",
                }}
                maxLength={255}
              />
            </div>
            <div className="price-summary blue-card">
              <div>
                <div className="price-breakdown">
                  <span>Base Amount</span>
                  <span>{formatCurrency(amount)}</span>
                </div>
                {!taxRules.taxExempt && taxRate && amount > 0 && (
                  <div className="price-breakdown">
                    <span>Tax %{taxRate}</span>
                    <span>
                      {formatCurrency((amount * (taxRate / 100)).toFixed(2))}
                    </span>
                  </div>
                )}
              </div>
              <div className="price-breakdown subtotal">
                <span>Subtotal</span>
                <span>
                  {taxRules.taxToTotal
                    ? formatCurrency(calculateTotal(amount, taxRate))
                    : formatCurrency(amount)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn--link"
          onClick={() => {
            setAdditionalFields(!showAdditionalFields);
          }}
        >
          <span>Additional Fields </span> {showAdditionalFields ? "-" : "+"}
        </button>
        <div className={`${showAdditionalFields ? "show" : "hide"}`}>
          <div
            className={`${
              showAdditionalFields ? "show" : "hide"
            } additional-fields`}
          >
            <RegisteredTextField
              name={"orderId"}
              label="Order ID"
              fullWidth
              v2={true}
              rules={{
                maxLength: validationRules.maxLength50,
              }}
              sx={{ marginBottom: "16px" }}
            />
            <RegisteredTextField
              name={"poNumber"}
              label="PO Number"
              rules={{
                maxLength: validationRules.maxLength50,
              }}
              fullWidth
              v2={true}
              sx={{ marginBottom: "8px" }}
            />
            {customFieldGroups?.length > 0 &&
              chosenCustomFields?.length > 0 && (
                <CustomFields
                  customFieldGroups={customFieldGroups}
                  chosenCustomFields={chosenCustomFields}
                  v2={true}
                  reset={resetCustomFields}
                />
              )}
          </div>
        </div>
        <CustomerSelect />
      </div>
    </div>
  );
};

export default PaymentDetails;
