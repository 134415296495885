import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import SearchTable from "../../Search/SearchTable";
import { getCustomerKeyValue } from "./customerHelpers";
import SearchPaging from "../../Search/SearchPaging";
import CustomerSearchActions from "./CustomerSearchActions";
import { Menu, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GatewayContext } from "../../../contexts/GatewayContext";

const CustomerSearchResults = ({
  customerSearchResults,
  userSettings,
  handleSortOnClick,
  handleChangeAlertDialogProps,
  loadCustomerSearchResults,
  showSnackBar,
  showAlertDialogError,
  resultsPerPage,
  resultsTotal,
  handleChange,
  handleResultsPerPageOnChange,
}) => {
  const keyInfoLookup = [
    {
      header: "Customer Name",
      key: "name",
      sortable: false,
      default_field: true,
    },
    { key: "payment_method", sortable: false, default_field: true },
    { key: "billing_address", sortable: false, default_field: true },
    { key: "created_date", sortable: false, default_field: true },
  ];
  const gatewayInfo = useContext(GatewayContext);
  const gatewaySettings = gatewayInfo.gatewaySettings.reduce((settings, s) => {
    settings[s.code] = s.value.toLowerCase() === "true";
    return settings;
  }, {});

  // eslint-disable-next-line no-unused-vars
  const [currentFieldList, setCurrentFieldList] = useState(
    keyInfoLookup.filter((row) => row.default_field === true),
  );
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const actionOpen = Boolean(actionAnchorEl);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleClick = (e, customer) => {
    setSelectedCustomer(customer);
    setActionAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setActionAnchorEl(null);
  };

  const showType =
    userSettings?.gatewayUserPermissions["VAULT_UPDATE"] ||
    userSettings?.gatewayUserPermissions["VAULT_DELETE"] ||
    (gatewaySettings["ALLOW_INVOICING"] &&
      userSettings.gatewayUserPermissions["CREATE_INVOICE"]) ||
    (gatewaySettings["ALLOW_INVOICING"] &&
      gatewaySettings["CUSTOMER_PAYABLE"] &&
      (userSettings?.gatewayUserPermissions["VIEW_CUST_PAYABLE_TOKEN"] ||
        userSettings?.gatewayUserPermissions["MANAGE_CUST_PAYABLE_TOKEN"]))
      ? "menu"
      : "list";

  const getActionsComponent = (row) => {
    return (
      <>
        {showType === "menu" ? (
          <IconButton aria-label="options" onClick={(e) => handleClick(e, row)}>
            <MoreVertIcon />
          </IconButton>
        ) : (
          <CustomerSearchActions
            customerSearchResult={row}
            userSettings={userSettings}
            handleChangeAlertDialogProps={handleChangeAlertDialogProps}
            loadCustomerSearchResults={loadCustomerSearchResults}
            showSnackBar={showSnackBar}
            showAlertDialogError={showAlertDialogError}
            showType={showType}
          />
        )}
      </>
    );
  };

  return (
    <>
      {showType === "menu" && (
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          className="action-menu"
          anchorEl={actionAnchorEl}
          open={actionOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <CustomerSearchActions
            customerSearchResult={selectedCustomer}
            userSettings={userSettings}
            handleChangeAlertDialogProps={handleChangeAlertDialogProps}
            loadCustomerSearchResults={loadCustomerSearchResults}
            showSnackBar={showSnackBar}
            showAlertDialogError={showAlertDialogError}
            showType={showType}
            handleCloseMenu={handleClose}
          ></CustomerSearchActions>
        </Menu>
      )}
      <SearchTable
        getEntityKeyValue={getCustomerKeyValue}
        fieldList={currentFieldList}
        searchResults={customerSearchResults}
        getActionsComponent={getActionsComponent}
        handleSortOnClick={handleSortOnClick}
      />
      <SearchPaging
        resultsPerPage={resultsPerPage}
        resultsTotal={resultsTotal}
        handleChange={handleChange}
        handleResultsPerPageOnChange={handleResultsPerPageOnChange}
      />
    </>
  );
};

CustomerSearchResults.propTypes = {
  customerSearchResults: PropTypes.array,
  resultsPerPage: PropTypes.number,
  resultsTotal: PropTypes.number,
  handleChange: PropTypes.func,
  handleResultsPerPageOnChange: PropTypes.func,
  fieldBoldness: PropTypes.string.isRequired,
  userSettings: PropTypes.object,
  handleSortOnClick: PropTypes.func,
  handleChangeAlertDialogProps: PropTypes.func,
  loadCustomerSearchResults: PropTypes.func,
  showSnackBar: PropTypes.func,
  showAlertDialogError: PropTypes.func,
};

export default CustomerSearchResults;
